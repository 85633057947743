// ////////////////////////////スマホ/タブレット判別
var _ua = (function (u) {
  return {
    Tablet:
      (u.indexOf('windows') != -1 && u.indexOf('touch') != -1) ||
      u.indexOf('ipad') != -1 ||
      (u.indexOf('android') != -1 && u.indexOf('mobile') == -1) ||
      (u.indexOf('firefox') != -1 && u.indexOf('tablet') != -1) ||
      u.indexOf('kindle') != -1 ||
      u.indexOf('silk') != -1 ||
      u.indexOf('playbook') != -1,
    Mobile:
      (u.indexOf('windows') != -1 && u.indexOf('phone') != -1) ||
      u.indexOf('iphone') != -1 ||
      u.indexOf('ipod') != -1 ||
      (u.indexOf('android') != -1 && u.indexOf('mobile') != -1) ||
      (u.indexOf('firefox') != -1 && u.indexOf('mobile') != -1) ||
      u.indexOf('blackberry') != -1,
  };
})(window.navigator.userAgent.toLowerCase());
// if(_ua.Mobile){}

var osVer;
osVer = 'Android';

var ua = navigator.userAgent.toLowerCase();
var iPadFlag = false;
if (/android|ipod|ipad|iphone|macintosh/.test(ua) && 'ontouchend' in document) {
  iPadFlag = true;
  $('#wrapper').addClass('setTouch');
}
// if (navigator.userAgent.indexOf(osVer)>0){
// }

var breakNum = 768;
var tabletNum = 1024;
// ////////////////////////////////////init
// $(function () {
//   // setScroll();
//   // if ($('#wrapper').hasClass('home')) {
//   //   setWave();
//   // }
//   // ///////////
//   // if (!_ua.Mobile && !_ua.Tablet) {
//   //   $('#wrapper').addClass('desktop');
//   //   if (!$('#wrapper').hasClass('about')) {
//   //     $('html, body').prop({
//   //       scrollTop: 0,
//   //     });
//   //     if (ua.indexOf('safari') !== -1 && ua.indexOf('chrome') === -1 && ua.indexOf('edge') === -1) {
//   //       // ここにSafari用の記述
//   //     } else {
//   //     }
//   //   }
//   // }
//   // ///////////
// });

$(window).on('load', function () {
  judgeWinSize();
  setHeader();
  setSpan();
  // setInview();
  setAcc();
  // setMegaMenu();
  startScroll();
  setMainMenu();
  setLoaded();
  // if ($('#wrapper').hasClass('home')) {
  //   switchMovie();
  //   setSlider_01();
  //   setKv();
  // } else if ($('#wrapper').hasClass('project')) {
  //   setSlider_02();
  //   setProjectPost();
  //   setProjectThumb();
  // }
  // setLangSwitch();
  setWay();
  // if ($('#wrapper').hasClass('mediakit')) {
  //   setCopy();
  // }
});

var current_scrollY = 0;

//テキストコピー
function setCopy() {
  const copyBtn = document.querySelector('.btnStyle_01.copyBtn');
  const labelTx = copyBtn.querySelector('.labelTx');
  const targetTx = document.querySelector('.targetTx').innerText;

  copyBtn.addEventListener('click', () => {
    // `navigator.clipboard`が使用可能かチェック
    if (navigator.clipboard && navigator.clipboard.writeText) {
      navigator.clipboard
        .writeText(targetTx)
        .then(() => {
          // ラベルを変更
          updateLabel(labelTx, 'コピーされました');
        })
        .catch((err) => {
          console.error('テキストのコピーに失敗しました: ', err);
        });
    } else {
      // フォールバックとして`execCommand`を使用
      const textarea = document.createElement('textarea');
      textarea.value = targetTx;
      document.body.appendChild(textarea);
      textarea.select();

      try {
        document.execCommand('copy');
        updateLabel(labelTx, 'コピーされました');
      } catch (err) {
        console.error('フォールバックによるコピーに失敗しました: ', err);
      }

      document.body.removeChild(textarea);
    }
  });

  function updateLabel(label, message) {
    const originalLabel = label.textContent;
    label.textContent = message;

    // 1秒後に元のテキストに戻す
    setTimeout(() => {
      label.textContent = originalLabel;
    }, 1000);
  }
}
//form
function setForm() {
  jQuery('.wpcf7-tel').attr('pattern', '\\d*');
  jQuery('.wpcf7-tel').attr(
    'onkeyup',
    'if(event.keyCode==9||event.keyCode==16) return; this.value=this.value.replace(/[^0-9]+/i,"")'
  );

  $('#confirmBtn').on('click', function () {
    $('.wpcf7c-btn-confirm').click();
  });
  $('#backBtn').on('click', function () {
    $('.wpcf7c-btn-back').click();
  });
  $('#submitBtn').on('click', function () {
    $('.wpcf7-submit').click();
  });

  // $('#press textarea').keypress(function () {
  //   $('.submitCol .sep').addClass('disp');
  // });

  // /////エラーのとき
  document.addEventListener(
    'wpcf7invalid',
    function (event) {
      setTimeout(function () {
        var position = $('.wpcf7-not-valid-tip:first').offset().top - 300;
        $('html,body').animate(
          {
            scrollTop: position,
          },
          {
            queue: false,
          }
        );
      }, 100);
    },
    false
  );
  // /////成功のとき
  document.addEventListener(
    'wpcf7mailsent',
    function (event) {
      $('html,body').animate(
        {
          scrollTop: 0,
        },
        {
          queue: false,
        }
      );
    },
    false
  );
}

// ///////////////////////////フォーム確認画面
function setConfirm() {
  var tx = $('[name=myname]').val();
  $('#nameTx').text(tx);

  var tx = $('[name=company]').val();
  $('#companyTx').text(tx);

  var tx = $('[name=mymail]').val();
  $('#mailTx').text(tx);

  var tx = $('[name=mytel]').val();
  $('#telTx').text(tx);

  var tx = $('[name=mycontent]').val();
  $('#mycontentTx').text(tx);
}

// カルーセル
function setSlider_01() {
  const mySwiperMain = new Swiper('.carouselStyle_01 .swiper-main', {
    slidesPerView: 2,
    spaceBetween: 20,
    watchSlidesProgress: true,
    loop: true,
    // preventClicks: false,
    // preventClicksPropagation: false,
    mousewheel: {
      forceToAxis: true,
    },
    // scrollbar: {
    //   el: '.swiper-scrollbar',
    //   draggable: true,
    // },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    speed: 1000, // スライドアニメーションのスピード（ミリ秒）
    breakpoints: {
      769: {
        slidesPerView: 5,
        spaceBetween: 36,
      },
      // 1000: {
      //   slidesPerView: 3,
      //   spaceBetween: 30,
      // },
    },
  });
}

// //////////////////////////メインメニュー
function setMainMenu() {
  $('#mainMenu').on('click', function () {
    if (!$('#mainMenu').hasClass('active')) {
      openFnc();
    } else {
      closeFnc();
    }
  });
  $('#clickBlocker,#menuPanel .closeBtn,#menuPanel .closeArea,#menuPanel .mainNav').on(
    'click',
    function (event) {
      event.stopPropagation();
      closeFnc();
    }
  );

  // $('.mainNav a').on('click', function (event) {
  //   event.stopPropagation();
  // });

  var winW = $(window).width();
  var timer = false;
  var currentWidth = window.innerWidth;
  window.addEventListener('resize', function () {
    winW = $(window).width();
    if (currentWidth == window.innerWidth) {
      return;
    }
    currentWidth = window.innerWidth;
    if (timer !== false) {
      clearInterval(timer);
    }
    timer = setTimeout(function () {
      if (winW > tabletNum) {
        closeFnc();
      }
    }, 200);
  });
}

function openFnc() {
  current_scrollY = $(window).scrollTop();
  $('#mainMenu').addClass('active');
  $('#wrapper').addClass('menuOpen');
  if ($('#wrapper').hasClass('setSp')) {
    $('#outerMenu').css('top', -current_scrollY);
  }
}

function closeFnc() {
  $('#mainMenu').removeClass('active');
  $('#outerMenu').css('top', '');
  $('#wrapper').removeClass('menuOpen');
  if ($('#wrapper').hasClass('setSp')) {
    $('html, body').prop({
      scrollTop: current_scrollY,
    });
  }
}

// ///////ヘッダー制御
function setHeader() {
  var timer = false;
  $(window).on('resize', function () {
    if (timer !== false) {
      clearTimeout(timer);
    }
    timer = setTimeout(function () {
      $('header').removeClass('fixedHeader');
      $('header').removeClass('fixedHeaderDelay');
    }, 10);
  });

  fixedHeader();
  // console.log($('#mainVis').height())

  $(window).scroll(function () {
    fixedHeader();
  });

  function fixedHeader() {
    if ($('#wrapper').hasClass('home')) {
      // h = $('#mainVis').height() / 2;
      h = 10;
    } else if ($('#wrapper').hasClass('setKv')) {
      h = $('#kv').height();
    } else {
      h = 5;
    }
    baseHeight = h;
    if ($(this).scrollTop() < baseHeight) {
      $('#wrapper').removeClass('fixedHeader');
    } else if ($(this).scrollTop() >= baseHeight) {
      $('#wrapper').addClass('fixedHeader');
    }
  }
}

// テキストアニメーション用span生成
function setSpan() {
  $('.txEffect>span').each(function () {
    var text = $.trim(this.textContent),
      html = '';

    text.split('').forEach(function (v) {
      html += '<span>' + v + '</span>';
    });

    this.innerHTML = html;
  });
}

// ///////waypoint
function setWay() {
  $('.way,.way-slow,.alphaWay,.js-way').waypoint(
    function (direction) {
      var activePoint = $(this.element);
      if (direction === 'down') {
        // scroll down
        activePoint.addClass('is-active');
        timer = setTimeout(function () {
          activePoint.addClass('is-active-after');
        }, 500);
      }
    },
    {
      offset: '70%',
    }
  );
  $('.js-item-way').waypoint(
    function (direction) {
      var activePoint = $(this.element);
      if (direction === 'down') {
        // scroll down
        activePoint.addClass('is-active-item');
        timer = setTimeout(function () {
          activePoint.addClass('is-active-item-after');
        }, 500);
      }
    },
    {
      offset: '70%',
    }
  );
  $('.video-way').waypoint(
    function (direction) {
      var activePoint = $(this.element);
      var videoElem = activePoint.find('video');
      if (direction === 'down') {
        videoElem.get(0).play();
      }
    },
    {
      offset: '70%',
    }
  );
}

// ////////////////////////////アコーディオン
function setAcc() {
  $('.toggleHead').on('click', function () {
    $(this).parent().toggleClass('active');
    $(this).next().stop().slideToggle('fast');
  });
  $('.pickUpSection .closeBtn').on('click', function () {
    $(this).parents('.pickUpSection').toggleClass('active');
    $(this).parents('.toggleContent').stop().slideToggle('fast');
  });
}

// //////////////////////////////ロード完了
function setLoaded() {
  $('#wrapper,#loading').addClass('loaded');
  loadStart();
}

function loadStart() {
  timer = setTimeout(function () {
    $('#wrapper,#loading').addClass('loadedDone');
  }, 200);
  timer = setTimeout(function () {
    $('#wrapper,#loading').addClass('loadedStart');
  }, 600);
  timer = setTimeout(function () {
    $('#wrapper').addClass('loadEnd');
  }, 1500);
  timer = setTimeout(function () {
    $('#wrapper').addClass('homeStart');
  }, 1200);
  if ($('#wrapper').hasClass('home')) {
    timer = setTimeout(function () {
      $('#wrapper').addClass('homeDelay');
    }, 2200);
  } else {
    timer = setTimeout(function () {
      $('#wrapper').addClass('pagesDelay');
    }, 500);
  }
  // $('body,html').animate({
  //   scrollTop: 0
  // }, 0, 'swing')
}

// ////////////ウィンドウサイズを判別
function judgeWinSize() {
  var winW = $(window).width();
  if (winW > breakNum) {
    $('#wrapper').addClass('setPc');
  } else {
    $('#wrapper').addClass('setSp');
  }

  var timer = false;
  var currentWidth = window.innerWidth;
  window.addEventListener('resize', function () {
    if (currentWidth == window.innerWidth) {
      return;
    }
    currentWidth = window.innerWidth;
    if (timer !== false) {
      clearTimeout(timer);
    }
    timer = setTimeout(function () {
      winW = $(window).width();
      if (winW > breakNum) {
        $('#wrapper').addClass('setPc');
        $('#wrapper').removeClass('setSp');
      } else {
        $('#wrapper').addClass('setSp');
        $('#wrapper').removeClass('setPc');
      }
    }, 200);
  });
}

// /////////////////////////////スムーススクロール
function setScroll() {
  var headerHeight = $('header').height();
  // var headerHeight = $('header .col-main').height() + 50;
  // if ($('#wrapper').hasClass('setPc')) {
  //   var headerHeight = 0;
  // } else {
  //   var headerHeight = 100;
  // }
  $('a[href^="#"]').click(function (e) {
    var href = $(this).attr('href');
    var target = $(href == '#' || href == '' ? 'html' : href);
    // var position = target.offset().top;

    if ($(this).hasClass('unqNav')) {
      closeFnc();
    }
    setTimeout(function () {
      var position = target.offset().top - headerHeight;
      $('html, body').animate(
        {
          scrollTop: position,
        },
        400,
        'swing'
      );
    }, 100);
    e.preventDefault();
    // $.when(
    //   $('html, body').animate(
    //     {
    //       scrollTop: position,
    //     },
    //     400,
    //     'swing'
    //   ),
    //   e.preventDefault()
    // ).done(function () {
    //   var diff = target.offset().top;
    //   if (diff === position) {
    //   } else {
    //     $('html, body').animate(
    //       {
    //         scrollTop: diff,
    //       },
    //       400,
    //       'swing'
    //     );
    //   }
    // });
  });
}

function startScroll() {
  // ページのURLを取得
  const url = $(location).attr('href'),
    // headerの高さを取得してそれに30px追加した値をheaderHeightに代入
    headerHeight = $('header').outerHeight() + 30;

  // urlに「#」が含まれていれば
  if (url.indexOf('#') != -1) {
    // urlを#で分割して配列に格納
    const anchor = url.split('#'),
      // 分割した最後の文字列（#◯◯の部分）をtargetに代入
      target = $('#' + anchor[anchor.length - 1]),
      // リンク先の位置からheaderHeightの高さを引いた値をpositionに代入
      position = Math.floor(target.offset().top) - headerHeight;
    // positionの位置に移動
    $('html, body').animate({ scrollTop: position }, 500);
  }
  setScroll();
}

// ////////////////////////////////////ランドスケープ判定
var isLandscape = function () {
  if (window.innerHeight > window.innerWidth) {
    jQuery('body').addClass('portrait');
    jQuery('body').removeClass('landscape');
  } else {
    jQuery('body').addClass('landscape');
    jQuery('body').removeClass('portrait');
  }
};

if (_ua.Mobile) {
  jQuery(window).resize(function () {
    isLandscape();
  });
  isLandscape();
}

function watchViewMode() {
  window.addEventListener('orientationchange', function () {
    // orientationchange イベントがトリガーされたときの処理をここに記述します
    location.reload(); // ページをリロードします
  });
}
